<template>
  <div id="stock-list">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="4">
            月次入出庫詳細(単品)
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" @click="reduceMonth">
            <v-icon class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
          </v-col>
          <v-col cols="2" align-self="center">
            <label>{{ selectedMonth.substring(0, 4) }}年{{ selectedMonth.substring(4, 6) }}月</label>
          </v-col>
          <v-col v-if="selectedMonth < currentMonth" cols="1">
            <v-icon class="me-1" @click="addMonth">
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-col>
          <v-col v-else cols="1">
          </v-col>
        </v-row>
      </v-card-title>
      <v-row align="center" class="mx-0 px-0 mt-1">
        <v-col cols="6" class="px-5 pt-0 align-self-end">
          <label style="font-size: 16px">単品コード: {{ $route.query.item_code }}</label>
          <label v-if="item_name"> ({{ item_name }})</label>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" class="px-5 pt-0">
          <div class="d-flex align-center flex-wrap">
            <v-spacer></v-spacer>
            <v-btn class="me-3" color="secondary" outlined @click="comeback">
              <v-icon size="17" class="me-1">
                {{ icons.mdiKeyboardReturn }}
              </v-icon>
              <span>戻る</span>
            </v-btn>
            <v-btn color="secondary" :loading="exportStatus" outlined @click="downloadCSV()">
              <v-icon size="17" class="me-1">
                {{ icons.mdiExportVariant }}
              </v-icon>
              <span>Export</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-3"></v-divider>
      <v-card-text class="pa-0">
        <v-row align="center" class="ma-0">
          <v-col cols="12" class="pa-0">
            <v-data-table
              :headers="headers"
              :items="ItemDailyStock"
              group-by="item_code"
              item-key="target_date"
              :items-per-page="-1"
            >
              <template v-slot:[`item.quantity_in`]="{ item }">
                {{ formatData(item.quantity_in) }}
              </template>
              <template v-slot:[`item.quantity_out`]="{ item }">
                <label :style="item.quantity_out > 0 ? 'color:#F44336' : ''">{{ formatData(item.quantity_out) }}</label>
              </template>

              <template v-slot:[`item.daily_qty`]="{ item }">
                <label :style="item.daily_qty < 0 ? 'color:#F44336' : ''">{{ formatData(item.daily_qty) }}</label>
              </template>
              <template v-slot:[`item.net_qty`]="{ item }">
                <label :style="item.net_qty < 0 ? 'color:#F44336' : ''">{{ formatData(item.net_qty) }}</label>
              </template>

              // clear group header
              <template v-slot:[`group.header`]>
              </template>

              <template v-slot:[`body.prepend`]>
                <tr>
                  <td style="background: #ffffff">
                    前月繰越分
                  </td>
                  <td style="background: #ffffff; text-align: right">
                    <a
                      v-if="monco_id > 0"
                      href="javascript:void(0)"
                      :style="`${qtyin_lmSum < 0 ? 'color:#F44336 !important;' : ''}`"
                      @click="openEditForm"
                    >
                      {{ formatData(qtyin_lmSum) }}</a>
                    <a v-else>{{ formatData(qtyin_lmSum) }}</a>
                  </td>
                  <td style="background: #ffffff; text-align: right;">
                    {{ formatData(qtyout_lmSum) }}
                  </td>
                  <td
                    :style="
                      `background:#FFFFFF;text-align:right;${
                        qtyin_lmSum - qtyout_lmSum < 0 ? 'color:#F44336 !important;' : ''
                      }`
                    "
                  >
                    {{ formatData(qtyin_lmSum - qtyout_lmSum) }}
                  </td>
                  <td
                    :style="`background: #ffffff; text-align: right;${net_qty < 0 ? 'color:#F44336 !important;' : ''}`"
                  >
                    {{ formatData(net_qty) }}
                  </td>
                </tr>
              </template>

              <template v-slot:[`group.summary`]>
                <td style="background: #ffffff; text-align: left">
                  合計
                </td>
                <td style="background: #ffffff; text-align: right">
                  {{ formatData(MonthlyQtyIn) }}
                </td>
                <td style="background: #ffffff; text-align: right; color: #f44336 !important">
                  {{ formatData(MonthlyQtyOut) }}
                </td>
                <td :style="`background:#FFFFFF;text-align:right;${MonthlyQty < 0 ? 'color:#F44336 !important;' : ''}`">
                  {{ formatData(MonthlyQty) }}
                </td>
                <td style="background: #ffffff; text-align: right"></td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <carryover-edit ref="carryoverEdit" @closeForm="loadData"></carryover-edit>
    </v-card>
  </div>
</template>

<script>
import {
  mdiKeyboardReturn, mdiChevronLeft, mdiChevronRight, mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

import CarryoverEdit from '@/views/bimi/stock/carryover/CarryoverEdit.vue'

export default {
  components: {
    CarryoverEdit,
  },
  data: () => ({
    exportStatus: false,
    icons: {
      mdiKeyboardReturn,
      mdiChevronLeft,
      mdiChevronRight,
      mdiExportVariant,
    },
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 7)
      .replace('-', ''),

    headers: [
      {
        text: '',
        align: 'left',
        value: 'target_date',
        width: '20%',
        fixed: true,
      },
      {
        text: '入荷数量',
        value: 'quantity_in',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '出荷数量',
        value: 'quantity_out',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '当日小計',
        value: 'daily_qty',
        align: 'right',
        width: '20%',
        fixed: true,
      },
      {
        text: '累計在庫',
        value: 'net_qty',
        align: 'right',
        width: '20%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('dailyStockStore', [
      'ItemDailyStock',
      'qtyin_lmSum',
      'qtyout_lmSum',
      'net_qty',
      'monco_id',
      'item_name',
    ]),
    selectedMonth() {
      return this.$route.params.month
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    MonthlyQtyIn() {
      let sum = 0
      if (this.ItemDailyStock) {
        this.ItemDailyStock.forEach(ids => {
          sum += ids.quantity_in
        })
      }
      sum += this.qtyin_lmSum

      return sum
    },
    MonthlyQtyOut() {
      let sum = 0
      if (this.ItemDailyStock) {
        this.ItemDailyStock.forEach(ids => {
          sum += ids.quantity_out
        })
      }

      sum += this.qtyout_lmSum

      return sum
    },
    MonthlyQty() {
      let sum = 0
      if (this.ItemDailyStock) {
        this.ItemDailyStock.forEach(cp => {
          sum += cp.daily_qty
        })
      }
      sum = sum + this.qtyin_lmSum - this.qtyout_lmSum

      return sum
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...mapActions('dailyStockStore', ['loadItemDailyStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    openEditForm() {
      console.log('openEditForm', this.monco_id)
      this.$refs.carryoverEdit.openForm(this.monco_id)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadItemDailyStock({
        month: this.$route.params.month,
        product_code: this.$route.query.item_code,
        type: this.$route.query.selTab,
      }).then(() => {
        this.setOverlayStatus(false)

        console.log('ItemDailyStocl loaded:', this.ItemDailyStock)
      })
    },
    comeback() {
      if (this.$route.query.from === 'current') {
        this.$router.push({
          name: 'stock-current',
          query: {
            selTab: this.$route.query.selTab,
          },
        })
      } else {
        this.$router.push({
          name: 'stock-monthly',
          params: {
            month: this.$route.params.month,
          },
          query: {
            selTab: this.$route.query.selTab,
          },
        })
      }
    },
    reduceMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() - 1))
        .toISOString()
        .substring(0, 7)
        .replace('-', '')
      console.log('id params:', this.$route.params.id, this.MthStocktabs)
      console.log('id query:', this.$route.query)
      if (selMonth <= this.currentMonth) {
        console.log('id query:', this.$route.query)
        this.$router.push({
          name: 'stock-item-daily',
          params: {
            month: selMonth,
          },
          query: {
            selTab: this.$route.query.selTab,
            item_code: this.$route.query.item_code,
          },
        })
      }
      this.loadData()
    },
    addMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() + 1))
        .toISOString()
        .substring(0, 7)
        .replace('-', '')
      console.log(this.Stocktabs)
      if (selMonth <= this.currentMonth) {
        this.$router.push({
          name: 'stock-item-daily',
          params: {
            month: selMonth,
          },
          query: {
            selTab: this.$route.query.selTab,
            item_code: this.$route.query.item_code,
          },
        })
      }
      this.loadData()
    },

    downloadCSV() {
      // this.exportStatus = true
      // agent.Report.monthlyReportDownload({
      //   date: this.selectedMonth,
      //   data: {
      //     type: 'product',
      //     target_ids: [this.$route.params.id],
      //   },
      // }).then(() => {
      //   this.exportStatus = false
      // })
    },
  },
}
</script>
